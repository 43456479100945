<template>

  <loader :active="loading" message="Please wait..." />

  <div class="admin-header-bottom">
    <nav class="admin-breadcrumb" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/">Dashboard</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Check SERP</li>
      </ol>
    </nav>
    <h1 class="page-title">Check SERP</h1>
    <!-- <div class="header-btn-cont">
      <a class="admin-common-btn" href="#" @click="checkSerp()">Check SERP</a>
    </div> -->
    <!-- Modal - Check SERP - START -->
    <!-- <AddKeywordDialog :visible="addKeyword" @update:visible="addKeyword = $event" title="Check SERP" tooltip="Custom Tooltip" /> -->
    <!-- Modal - Check SERP - END -->
  </div>

  <div class="admin-body">
    <div class="admin-body-box">
      <!-- <div class="admin-body-top">
      </div> -->
      <div class="admin-body-bottom">
        <div class="table-top">
          <div class="header-top-btn-cont">
            <a class="admin-common-btn" href="#" @click="checkSerp()">Check SERP</a>
          </div>
          <!-- Modal - Check SERP - START -->
          <AddKeywordDialog :visible="addKeyword" @update:visible="addKeyword = $event" title="Check SERP"
            tooltip="Custom Tooltip" />
          <!-- Modal - Check SERP - END -->
          <input class="table-search-input" placeholder="Search Domain.." v-model="searchQuery" />
          <!-- <div class="btn-cont">
            <a class="admin-common-btn" href="#" @click="expandAll">+ Expand All</a>
            <a class="admin-common-btn" href="#" @click="collapseAll">- Collapse All</a>
          </div> -->
        </div>
        <div class="data-table-cont">
          <div id="example_wrapper" class="dataTables_wrapper">
            <div class="row dt-row">
              <div class="col-sm-12">
                <div class="table-responsive table-check-serp">
                  <table id="example" class="data-table table table-striped table-border-none">
                    <thead class="sticky-top">
                      <tr>
                        <th class="no-wrap">ID</th>
                        <th class="no-wrap">Domain</th>
                        <th class="no-wrap">Location</th>
                        <th class="no-wrap">No. Of Keywords</th>
                        <th class="no-wrap">Date</th>
                        <th class="no-wrap">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="(item, index) in keywords">
                        <tr
                          :class="{ 'odd': index % 2 === 0, 'even': index % 2 !== 0, 'highlight': index === 0 && highlighted }">
                          <td class="no-wrap">{{ item.id }}</td>
                          <td class="no-wrap">{{ item.url }}</td>
                          <td class="no-wrap">{{ item.location }}</td>
                          <td class="no-wrap">{{ item.keywords_count }}</td>
                          <td class="no-wrap responsive-wrap">{{ item.date.split('-').reverse().join('-') }} <br> {{
    item.time }}</td>
                          <!-- <td class="action-buttons" style="display: flex; justify-content: center; cursor: pointer;"> -->
                          <td class="no-wrap action-buttons" style="white-space: nowrap;">
                            <a class="toggleChild" @click="toggleItem(index)" title="Toggle">
                              <img class="icon-down-arrow" src="images/icon-down-arrow-data-table.svg"
                                alt="Down Arrow Icon" />
                            </a>
                            <a class="refresh-icon-cont" @click="refreshItem(item)" title="Refresh">
                              <img class="icon-refresh" src="images/icon-refresh-data-table.svg" alt="Refresh Icon" />
                            </a>
                            <a @click="exportToExcel(item)" title="Export to Excel">
                              <img class="icon-excel" src="images/icon-excel.svg" alt="Excel Export Icon" />
                            </a>
                          </td>
                        </tr>
                        <tr v-if="selectedRow === index">
                          <td :colspan="6">
                            <div class="additional-info-cont">
                              <div class="info-top">
                                <h2 class="top-title">DOMAIN : <a class="link">{{ item.url }}</a></h2>
                              </div>
                              <div class="info-body">
                                <table class="table-additional-info table ">
                                  <thead>
                                    <tr>
                                      <th>Keyword</th>
                                      <th style="text-align: left">Ranked Url</th>
                                      <th>Your Rank</th>
                                      <th v-if="item.items[0].competitor1 !== null && item.items[0].competitor1.name">
                                        {{ item.items[0].competitor1.name }}
                                      </th>
                                      <th v-if="item.items[0].competitor2 !== null && item.items[0].competitor2.name">
                                        {{ item.items[0].competitor2.name }}
                                      </th>
                                      <th v-if="item.items[0].competitor3 !== null && item.items[0].competitor3.name">
                                        {{ item.items[0].competitor3.name }}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="(keywordItem, keywordIndex) in item.items"
                                      :key="'keyword-' + keywordItem.id"
                                      :class="{ 'odd': keywordIndex % 2 === 0, 'even': keywordIndex % 2 !== 0 }">
                                      <td>{{ keywordItem.keyword }}</td>
                                      <td style="text-align: left;">
                                      <a v-if="keywordItem.url" :href="keywordItem.url" target="_blank" style="
                                          width: 250px;
                                          white-space: nowrap;
                                          overflow: hidden;
                                          text-overflow: ellipsis;
                                          display: inline-block;
                                          color: #007BFF; /* Optional: change the color for better visibility */
                                      ">🔗{{ keywordItem.url }}</a>
                                      <span v-else style="
                                          width: 250px;
                                          white-space: nowrap;
                                          overflow: hidden;
                                          text-overflow: ellipsis;
                                          display: inline-block;
                                      ">No URL available</span>
                                  </td>
                                      <td>
                                        <span class="rank">
                                          {{ keywordItem.rank }}
                                          <span
                                            v-if="isTopRank(keywordItem.rank, keywordItem.competitor1?.rank, keywordItem.competitor2?.rank, keywordItem.competitor3?.rank)">
                                            <img class="icon-up-arrow-green"
                                              src="images/icon-up-arrow-green-data-table.svg" alt="Up Arrow Icon">
                                          </span>
                                        </span>
                                      </td>
                                      <td v-if="keywordItem.competitor1 !== null && keywordItem.competitor1.name">
                                        <span class="rank">
                                          {{ keywordItem.competitor1.rank }}
                                          <span
                                            v-if="isTopRank(keywordItem.competitor1.rank, keywordItem.rank, keywordItem.competitor2?.rank, keywordItem.competitor3?.rank)">
                                            <img class="icon-up-arrow-green"
                                              src="images/icon-up-arrow-green-data-table.svg" alt="Up Arrow Icon">
                                          </span>
                                        </span>
                                      </td>
                                      <td v-if="keywordItem.competitor2 !== null && keywordItem.competitor2.name">
                                        <span class="rank">
                                          {{ keywordItem.competitor2.rank }}
                                          <span
                                            v-if="isTopRank(keywordItem.competitor2.rank, keywordItem.rank, keywordItem.competitor1?.rank, keywordItem.competitor3?.rank)">
                                            <img class="icon-up-arrow-green"
                                              src="images/icon-up-arrow-green-data-table.svg" alt="Up Arrow Icon">
                                          </span>
                                        </span>
                                      </td>
                                      <td v-if="keywordItem.competitor3 !== null && keywordItem.competitor3.name">
                                        <span class="rank">
                                          {{ keywordItem.competitor3.rank }}
                                          <span
                                            v-if="isTopRank(keywordItem.competitor3.rank, keywordItem.rank, keywordItem.competitor1?.rank, keywordItem.competitor2?.rank)">
                                            <img class="icon-up-arrow-green"
                                              src="images/icon-up-arrow-green-data-table.svg" alt="Up Arrow Icon">
                                          </span>
                                        </span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>

                </div>
                <!-- <div class="pagination-container">
                  <PaginationComponent :currentPage="currentPage" :lastPage="lastPage" :projectId="projectId"
                    :fetchPage="serpsList" />
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="pagination-container">
    <PaginationComponent :currentPage="currentPage" :lastPage="lastPage" :projectId="projectId"
      :fetchPage="serpsList" />
  </div>

  <div class="copyright-cont">
    <p class="copyright-text">&copy; 2024 Plus Promotions UK LTD | Registered in UK, Company Number 13871077, VAT Number
      438499640</p>
  </div>

</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import AddKeywordDialog from "@/components/AddKeywordDialog.vue";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import PaginationComponent from "@/components/PaginationComponent.vue";
import axiosIns from '@/axios';
import EventBus from '@/EventBus';
import loader from '../Loader.vue';

const addKeyword = ref(false);
const keywords = ref([]);
const selectedRow = ref(null);
const searchQuery = ref('');
const loading = ref(false)
const currentPage = ref(1);
const lastPage = ref(1);
const highlighted = ref(false); // Whether the first row is highlighted

const checkSerp = async () => {
  addKeyword.value = true
  EventBus.$emit('addKeyword', true);

}

const serpsList = async (page, highlight) => {
  //loading.value = true;

  try {
    const data = await axiosIns.get(`serp/list-serps?page=${page}&search=${searchQuery.value}`);
    //loading.value = false;
    keywords.value = data.data.data;
    currentPage.value = Number(data.data.pagination.current_page);
    lastPage.value = Number(data.data.pagination.last_page);
    localStorage.setItem('userData', JSON.stringify(data.data.userData));
    EventBus.$emit('loadCredits', true);

    if (highlight) {
      highlightFirstRow()
    }
    if (!data.data.isItemNull) {
      toast.success('Data Loaded!', {
        position: toast.POSITION.TOP_RIGHT,
        duration: 2000 // 3000 milliseconds timeout
      });
    }

    if (data.data.isItemNull) {
      setTimeout(() => {
        serpsList(page, true);
      }, 2000)
    }


  } catch (error) {
    console.error('Error fetching serps data:', error);

    toast.error('Failed to fetch serps data. Please try again later', {
      position: toast.POSITION.TOP_RIGHT,
      duration: 6000 // 6000 milliseconds timeout
    });
  }
};



const exportToExcel = async (data) => {
  try {
    const response = await axiosIns.post('/excel/generate-serps-with-id', data, {
      responseType: 'blob' // Specify the response type as 'blob' to receive binary data
    });

    // Create a blob object from the response data
    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Create a temporary URL for the blob object
    const url = window.URL.createObjectURL(blob);

    // Create a temporary link element
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'export.xlsx');

    // Append the link to the document body and trigger the click event
    document.body.appendChild(link);
    link.click();

    // Cleanup: remove the link and revoke the URL
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);

    toast.success('Excel Generated!', {
      position: toast.POSITION.TOP_RIGHT,
      duration: 2000 // 3000 milliseconds timeout
    });
  } catch (error) {
    toast.error('Error while generating excel!', {
      position: toast.POSITION.TOP_RIGHT,
      duration: 2000 // 3000 milliseconds timeout
    });
  }
}


// Method to toggle the selected row
const toggleItem = (index) => {
  // Toggle the selected row
  selectedRow.value = selectedRow.value === index ? null : index;
};


const isTopRank = (currentRank, competitor1Rank, competitor2Rank, competitor3Rank) => {
  // Convert ranks to numbers, or use a default value if not numeric
  currentRank = Number(currentRank) || Infinity;
  competitor1Rank = Number(competitor1Rank) || Infinity;
  competitor2Rank = Number(competitor2Rank) || Infinity;
  competitor3Rank = Number(competitor3Rank) || Infinity;

  // Perform the comparison
  return currentRank < competitor1Rank && currentRank < competitor2Rank && currentRank < competitor3Rank;
};

const refreshItem = async (data) => {

  const currentDate = new Date();
  const lastRefreshDate = new Date(data.date + ' ' + data.time);

  const timeDifference = currentDate - lastRefreshDate;
  const hoursDifference = timeDifference / (1000 * 60 * 60);


  if (hoursDifference <= 4) {
    const shouldRefresh = window.confirm(
      "Google's index updates infrequently, so daily checks are advised. Click 'Confirm' to proceed with a new search anyway"
    );

    if (!shouldRefresh) {
      return false;
    }
  }

  try {
    await axiosIns.post('/serp/refresh-serp', data);
    toast.success('Serp Item Refreshed', {
      position: toast.POSITION.TOP_RIGHT,
      duration: 2000 // 3000 milliseconds timeout
    });
    highlightFirstRow();
    serpsList(1);
  } catch (error) {
    if (error.response) {
      // If there's a response from the server, display its message in the toast
      toast.error(`Error Refreshed Serp: ${error.response.data.message}`, {
        position: toast.POSITION.TOP_RIGHT,
        duration: 2000 // 3000 milliseconds timeout
      });
    } else {
      // If there's no response from the server, display a generic error message
      toast.error('Error Refreshed Serp', {
        position: toast.POSITION.TOP_RIGHT,
        duration: 2000 // 3000 milliseconds timeout
      });
    }
    console.error('Error fetching user data:', error);
  }

};

const highlightFirstRow = () => {
  highlighted.value = true; // Set highlighted to true
  setTimeout(() => {
    highlighted.value = false; // Reset highlighted to false after 5 seconds
  }, 10000); // 5000 milliseconds = 5 seconds
};
watch(searchQuery, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    currentPage.value = 1; // Reset current page when search query changes
    serpsList(currentPage.value)
  }
});

onMounted(() => {

  EventBus.$on('loadSerps', data => {
    if (data) {
      serpsList(currentPage.value, true);
    }
  })
  serpsList(currentPage.value);
});


</script>

<style scoped>
.action-buttons {
  /* display: flex;
align-items: center; */
}

.action-buttons a {
  margin-right: 10px;
  position: relative;
}

.action-buttons a:last-child {
  margin-right: 0;
}

.action-buttons img {
  /* width: 24px; */
  /* Adjust icon size as needed */
  /* height: 24px; */
  /* Adjust icon size as needed */
}

.action-buttons a:hover::after {
  /* content: attr(title);
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 4px;
  position: absolute;
  top: calc(100% + 4px);
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 999; */
}

.dataTables_empty {
  display: none;
}

/* Add this CSS to your stylesheet */
.rank {
  font-weight: bold;
}

.pagination-container {
  display: flex;
  justify-content: center;
}

.highlight {
  background-color: #8ad8ff;
}
</style>