<template>
    <loader :active="loading" message="Please wait..." />

    <div class="admin-header-bottom">
        <nav class="admin-breadcrumb" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Check Domain Ranking</li>
            </ol>
        </nav>
        <h1 class="page-title">Check Domain Ranking</h1>
    </div>

    <div class="admin-body">
        <div class="construction-message">
            <div class="construction-emoji">🧑🏻‍💻</div>
            <h2 class="title">This Module is Under Testing</h2>
            <p class="message">We are testing this module and will put it live soon. Stay tuned!</p>
            <ul class="feature-list">
                <li>🔑 Keyword</li>
                <li>📊 Average Search Volume</li>
                <li>📈 Rank Position</li>
                <li>🌐 Ranking URL</li>
                <li>💰 Average CPC</li>
                <li>🔗 Domain Rating (DR)</li>
                <li>📌 URL Rating (UR)</li>
                <li>🚦 Domain Traffic</li>
                <li>🚗 Page Traffic</li>
                <li>🔗 Linked Domains</li>
                <li>↗️ External Links</li>
            </ul>
            <p class="launch-message">🚀 We will be launching these features soon. <br>
                <span>We're also adding the following and more features:</span> <br>
                <span><b>Real-time data</b> with <b>export report</b> functionality soon.</span>
            </p>
        </div>
    </div>

    <div class="copyright-cont">
        <p class="copyright-text">&copy; 2024 Plus Promotions UK LTD | Registered in UK, Company Number 13871077, VAT
            Number 438499640</p>
    </div>
</template>

<script setup>
const loading = false; // Set loading state appropriately
</script>

<style scoped>
.admin-header-bottom {
    padding-bottom: 20px;
}

.breadcrumb {
    background-color: transparent;
}

.page-title {
    margin: 0;
    font-size: 28px;
    color: #333;
}

.admin-body {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 60vh;
}

.construction-message {
    text-align: center;
    background-color: #f8f9fa;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 0 auto;
}

.construction-emoji {
    font-size: 50px;
    margin-bottom: 20px;
}

.title {
    font-size: 24px;
    margin-bottom: 10px;
    color: #007bff;
}

.message {
    font-size: 18px;
    margin-bottom: 20px;
    color: #555;
}

.feature-list {
    list-style-type: none;
    padding: 0;
    text-align: left;
    font-size: 16px;
    color: #333;
}

.feature-list li {
    margin-bottom: 10px;
    padding-left: 20px;
    position: relative;
}

.feature-list li::before {
    content: '';
    position: absolute;
    left: 0;
}

.launch-message {
    margin-top: 20px;
    font-size: 16px;
    color: #555;
}
</style>
