<template>
    <div class="log-cont">
        <div class="container-fluid log-container">
            <div class="row log-row">
                <div class="col-xs-12 col-md-6 col-lg-6 col-left">
                    <div class="left-cont">
                        <div class="log-form-box">
                            <form @submit.prevent="submitForm">
                                <div class="logo-for-responsive">
                                    <router-link to="javascript:void(0);" class="log-logo">
                                        <img class="img-fluid" src="images/logo-pluspromotions.png"
                                            alt="Plus Promotions">
                                    </router-link>
                                    <router-link to="/login" class="log-logo">
                                        <img class="img-fluid" src="images/logo-login.png" alt="SerpPlus">
                                    </router-link>
                                </div>
                                <h1 class="log-title">Sign In</h1>
                                <p class="log-sub-title">Enter your email and password to sign in!</p>
                                <!-- <a class="signin-with-google" href="javascript:void(0);">
                                    <img class="g-logo" src="images/logo-google.svg" alt="Google">
                                    <span>Sign in with Google</span>
                                </a> -->
                                <div class="form-group">
                                    <label>Email<span class="mand-star">*</span></label>
                                    <input v-model="form.email" class="form-control" type="email"
                                        placeholder="mail@example.com"
                                        :class="{ 'is-invalid': emailValidationFailed }" />
                                    <div v-if="emailValidationFailed" class="invalid-feedback">Please enter a valid
                                        email
                                        address.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Password<span class="mand-star">*</span></label>
                                    <input v-model="form.password" class="form-control" type="password"
                                        placeholder="Min. 8 characters"
                                        :class="{ 'is-invalid': passwordValidationFailed }" />
                                    <div v-if="passwordValidationFailed" class="invalid-feedback">Password must be at
                                        least
                                        8
                                        characters.</div>
                                </div>
                                <!-- <div class="form-devider">
                                    <span>or</span>
                                </div> -->
                                <div class="rm-fp-cont d-flex justify-content-between">
                                    <div class="form-check custom-check">
                                        <!-- <label class="form-check-label"><input class="form-check-input" type="checkbox">
                                            Remember me</label> -->
                                        <router-link to="/forgot-password" class="form-a">Forgot password</router-link>

                                    </div>
                                    <div class="form-text forgot-pass">
                                    </div>
                                </div>
                                <Button class="form-common-btn btn btn-primary w-100" type="submit"
                                    :loading="isLoading">Sign In</Button>

                                <div class="form-text forgot-pass-cra">
                                    Not registered yet? <router-link to="/register" class="form-a">Create an
                                        Account</router-link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-md-6 col-lg-6 col-right">
                    <div class="right-cont">
                        <a href="javascript:void(0);" class="router-link-active router-link-exact-active log-logo">
                            <img class="img-fluid" src="images/logo-pluspromotions.png" alt="Plus Promotions">
                        </a>
                        <a href="/login" class="router-link-active router-link-exact-active log-logo">
                            <img class="img-fluid" src="images/logo-login.png" alt="SerpPlus">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>


</template>
<script setup>
import { ref, inject } from 'vue';
import { AbilityBuilder, createMongoAbility } from '@casl/ability';
import { ABILITY_TOKEN } from '@casl/vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
const ability = inject(ABILITY_TOKEN)
const router = useRouter();
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { APP_URL } from '@/Constants';

const form = ref({
    email: '',
    password: ''
});

const isLoading = ref(false)

const emailValidationFailed = ref(false);
const passwordValidationFailed = ref(false);

async function submitForm() {
    emailValidationFailed.value = false;
    passwordValidationFailed.value = false;

    if (!isValidEmail(form.value.email)) {
        emailValidationFailed.value = true;
        toast.error("Invalid email format.", {
            position: toast.POSITION.TOP_RIGHT,
            duration: 6000 // 6000 milliseconds timeout
        });
        return;
    }

    if (form.value.password.length < 8) {
        passwordValidationFailed.value = true;
        toast.error("Password must be at least 8 characters long.", {
            position: toast.POSITION.TOP_RIGHT,
            duration: 6000 // 6000 milliseconds timeout
        });
        return;
    }

    try {
        isLoading.value = true

        const response = await axios.post(`${APP_URL}/login`, form.value);

        if (response.data.otp) {
            toast.success("Please check your email for OTP!", {
                position: toast.POSITION.TOP_RIGHT,
                duration: 3000 // 6000 milliseconds timeout
            });

            setTimeout(() => {
                router.push('/otp-verify');
            }, 3000);
        } else {
            const accessToken = response.data.accessToken;
            const userAbilities = response.data.userAbilities;
            const userData = response.data.userData;
            const plan = response.data.plan;

            localStorage.setItem('accessToken', JSON.stringify(accessToken));
            localStorage.setItem('userAbilities', JSON.stringify(userAbilities));
            localStorage.setItem('userData', JSON.stringify(userData));
            localStorage.setItem('plan', JSON.stringify(plan));

            isLoading.value = false;
            await getUserAbilities();
            toast.success("Login Successful!", {
                position: toast.POSITION.TOP_RIGHT,
                duration: 6000 // 6000 milliseconds timeout
            });

            setTimeout(() => {
                window.location.href = '/check-serp';
            }, 1000);

            // setTimeout(() => {
            //     router.push('/check-serp');
            // }, 3000);
        }


    } catch (error) {
        isLoading.value = false
        // Handle specific error cases
        if (error.response) {
            if (error.response.status === 401) {
                // Unauthorized access (invalid email or password)
                const errorMessage = error.response.data && error.response.data.error
                    ? error.response.data.error
                    : 'Invalid email or password';
                toast.error(errorMessage, {
                    position: toast.POSITION.TOP_RIGHT,
                    duration: 6000 // 6000 milliseconds timeout
                });
            } else {
                // Other HTTP errors
                const errorMessage = error.response.data && error.response.data.error
                    ? error.response.data.error
                    : 'An unexpected error occurred.';
                toast.error(errorMessage, {
                    position: toast.POSITION.TOP_RIGHT,
                    duration: 6000 // 6000 milliseconds timeout
                });
            }
        } else if (error.request) {
            // The request was made, but no response was received
            toast.error("No response received from the server.", {
                position: toast.POSITION.TOP_RIGHT,
                duration: 6000 // 6000 milliseconds timeout
            });
        } else {
            // Something happened in setting up the request that triggered an error
            toast.error("An unexpected error occurred.", {
                position: toast.POSITION.TOP_RIGHT,
                duration: 6000 // 6000 milliseconds timeout
            });
        }
    }
}

const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

async function getUserAbilities() {
    try {
        const abilitiesJson = localStorage.getItem('userAbilities');

        if (abilitiesJson) {
            const abilities = JSON.parse(abilitiesJson);

            const { can, rules } = new AbilityBuilder(createMongoAbility);
            can(abilities);

            ability.update(rules);
        } else {
            console.error('User abilities not found in localStorage');
        }
    } catch (error) {
        console.error('Error retrieving user abilities:', error);
    }
}

</script>

<style scoped>
.is-invalid {
    border-color: red;
}

.invalid-feedback {
    color: red;
    font-size: 14px;
}
</style>