import { createRouter, createWebHistory } from 'vue-router';
import Login from '@/views/auth/Login.vue';
import Otp from '@/views/auth/Otp.vue';
import Register from '@/views/auth/Register.vue';
import ForgotPassword from '@/views/auth/ForgotPassword.vue';
import ChangePassword from '@/views/auth/ChangePassword.vue';
import AdminLayout from '@/layouts/AdminLayout.vue';
import Dashboard from '@/views/Dashboard.vue';
import CheckSerp from '@/views/serp/CheckSerp.vue';
import DomainCheck from '@/views/domain/DomainCheck.vue';
import ListProject from '@/views/project/ListProject.vue';
import ViewProject from '@/views/project/ViewProject.vue';
import Profile from '@/views/users/Profile.vue';
import UserList from '@/views/users/List.vue';
import RoleList from '@/views/roles/List.vue';
import PermissionList from '@/views/permission/List.vue';
import PurchaseList from '@/views/plansCutomer/List.vue';
import CreditsList from '@/views/creditsList/List.vue';
import PlanList from '@/views/planList/List.vue';
import CustomerList from '@/views/customerList/List.vue';
import ViewCustomer from '@/views/customerList/View.vue';
import FaqCategoryList from '@/views/faq/List.vue';
import FaqQuestionList from '@/views/faq/FaqList.vue';
import SettingList from '@/views/settings/List.vue';
import PaymentDone from '@/views/payment/Done.vue';
import PaymentFailed from '@/views/payment/Failed.vue';
import FaqCustomer from '@/views/faqCustomer/List.vue';
import EventBus from '@/EventBus';

const routes = [
    {
        path: '/',
        component: AdminLayout,
        children: [
            {
                path: '',
                name: 'dashboard',
                component: Dashboard,
            },
            {
                path: '/check-serp',
                name: 'checkserp',
                component: CheckSerp,
            },
            {
                path: '/domain-list',
                name: 'domainlist',
                component: DomainCheck,
            },
            {
                path: '/list-project',
                name: 'listproject',
                component: ListProject,
            },
            {
                path: '/view-project/:id',
                name: 'viewproject',
                component: ViewProject,
            },
            {
                path: '/user-profile',
                name: 'profile',
                component: Profile,
            },
            {
                path: '/user-list',
                name: 'userList',
                component: UserList,
            },
            {
                path: '/role-list',
                name: 'roleList',
                component: RoleList,
            },
            {
                path: '/permission-list',
                name: 'permissionList',
                component: PermissionList,
            },
            {
                path: '/purchase-list',
                name: 'purchaseList',
                component: PurchaseList,
            },
            {
                path: '/credits-list',
                name: 'creditsList',
                component: CreditsList,
            },
            {
                path: '/plan-list',
                name: 'planList',
                component: PlanList,
            },
            {
                path: '/customer-list',
                name: 'customerList',
                component: CustomerList,
            },
            {
                path: '/view-customer/:id',
                name: 'viewCustomer',
                component: ViewCustomer,
            },
            {
                path: '/faq-category-list',
                name: 'faqCategoryList',
                component: FaqCategoryList,
            },
            {
                path: '/faq-questions-list',
                name: 'faqQuestionList',
                component: FaqQuestionList,
            },
            {
                path: '/settings-list',
                name: 'settingList',
                component: SettingList,
            },
            {
                path: '/faq-customer',
                name: 'faqCustomer',
                component: FaqCustomer,
            },

        ],
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/otp-verify',
        name: 'otpverify',
        component: Otp
    },
    {
        path: '/register',
        name: 'register',
        component: Register
    },
    {
        path: '/forgot-password',
        name: 'forgotpassword',
        component: ForgotPassword
    },
    {
        path: '/changepassword/:token',
        name: 'changepassword',
        component: ChangePassword
    },
    {
        path: '/payment-done',
        name: 'paymentDone',
        component: PaymentDone,
    },
    {
        path: '/payment-failed',
        name: 'paymentFailed',
        component: PaymentFailed,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes
});


const publicRoutes = ['login', 'register', 'forgotpassword', 'changepassword', 'otpverify'];

router.beforeEach((to, from, next) => {
    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken && !publicRoutes.includes(to.name)) {
        next('/login');
    } else if (accessToken && publicRoutes.includes(to.name)) {
        next('/');
    } else {
        EventBus.$emit('loadCredits', true);

        next();
    }
});


export default router;

router.afterEach(() => {
    const nav = document.getElementById('nav-bar'),
        toggle = document.getElementById('header-toggle'),
        bodypd = document.getElementById('body-pd'),
        headerpd = document.getElementById('header');
    const toggleIconCont = document.getElementById('header-toggle');

    if (!nav.classList.contains('show')) {
        nav.classList.add('show');
        toggle.classList.add('bx-x');
        bodypd.classList.add('body-pd');
        headerpd.classList.add('body-pd');
    }

    // Check if navigation is closed
    const isNavClosed = !nav.classList.contains('show');

    // Add or remove 'bx-x' class based on the navigation state
    toggleIconCont.classList.toggle('bx-x', isNavClosed);
});
